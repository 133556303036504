// This file was automatically generated from widget.dropdown.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Widget == 'undefined') { Nightloop.Templates.Widget = {}; }


Nightloop.Templates.Widget.DropDown = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div ', (opt_data.id) ? 'id="' + soy.$$escapeHtml(opt_data.id) + '" ' : '', 'class="float-left widget-dropdown-container  ', (opt_data.disabled && opt_data.disabled != 'false') ? 'disabled' : '', '  ', (opt_data.classes) ? soy.$$escapeHtml(opt_data.classes) : '', '"><a class=\'widget-dropdown-selected-link\'>', (opt_data.selected) ? soy.$$escapeHtml(opt_data.selected) : '-', ' </a><input type="hidden" class=\'widget-dropdown-selected-value\' name=\'', soy.$$escapeHtml(opt_data.name), '\'/><div class=\'widget-dropdown\'>');
  var optionList17161 = opt_data.options;
  var optionListLen17161 = optionList17161.length;
  for (var optionIndex17161 = 0; optionIndex17161 < optionListLen17161; optionIndex17161++) {
    var optionData17161 = optionList17161[optionIndex17161];
    output.append('<div class=\'widget-dropdown-option\'><div class=\'widget-dropdown-option-text\'>', soy.$$escapeHtml(optionData17161[0]), '</div><div class=\'widget-dropdown-option-value no-display\'>', soy.$$escapeHtml(optionData17161[1]), '</div></div>');
  }
  output.append('</div></div>');
  return opt_sb ? '' : output.toString();
};
