// This file was automatically generated from widget.followers.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Widget == 'undefined') { Nightloop.Templates.Widget = {}; }


Nightloop.Templates.Widget.Followers = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="followers-component">', (opt_data.title) ? '<div class=\'title\'>Followers</div>' : '', '<span class="frozen-followers-area">');
  var followerList17176 = opt_data.frozenFollowers;
  var followerListLen17176 = followerList17176.length;
  for (var followerIndex17176 = 0; followerIndex17176 < followerListLen17176; followerIndex17176++) {
    var followerData17176 = followerList17176[followerIndex17176];
    output.append('<span class="pic">', soy.$$escapeHtml(followerData17176.initials), '<em class=\'name\'>', soy.$$escapeHtml(followerData17176.full_name), '</em></span>');
  }
  output.append('</span><span class="followers-area">');
  var followerList17184 = opt_data.followers;
  var followerListLen17184 = followerList17184.length;
  for (var followerIndex17184 = 0; followerIndex17184 < followerListLen17184; followerIndex17184++) {
    var followerData17184 = followerList17184[followerIndex17184];
    output.append('<span class="pic">', soy.$$escapeHtml(followerData17184.initials), '<span class="del follower-remove" fid="', soy.$$escapeHtml(followerData17184.id), '">x</span>', (opt_data.includeInput) ? '<input type="hidden" name="fid" value="' + soy.$$escapeHtml(followerData17184.id) + '" />' : '', '<em class=\'name\'>', soy.$$escapeHtml(followerData17184.full_name), '</em></span>');
  }
  output.append('</span><span id="insert-followers" class="add"><img src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/add_2x.png" /><select id="followers-select"></select></span></div>');
  return opt_sb ? '' : output.toString();
};
